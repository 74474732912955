<template>
  <metainfo>
    <template v-slot:title="{ content }">
      {{ content ? `${content} | SA Admin` : `SA Admin` }}
    </template>
  </metainfo>
  <Toasts />
  <router-view />
</template>
<script>
import Toasts from "@/components/Toasts";
import { mapGetters } from "vuex";
export default {
  metaInfo: () => ({
    title: "",
  }),
  components: {
    Toasts,
  },
  computed: {
    ...mapGetters(["getTheme"]),
  },
  created() {
    this.getTheme === "dark" ? document.body.classList.add("dark") : "";
  },
};
</script>
<style lang="scss">
html {
  height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
  padding: 0;
}

.table {
  color: var(--bs-body-color);
  background-color: transparent;
}

#app {
  font-family: "Plus Jakarta Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  min-height: 100vh;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

.input-group > .d-input,
.input-group > .d-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group > .d-input:focus,
.input-group > .d-select:focus {
  z-index: 5;
}

.d-select {
  display: block;
  width: 100%;
  padding: 6px 36px 6px 12px;
  color: #2e2e2e;
  border-radius: 6px;
  background-color: hsl(0, 0%, 100%);
  border: 1px solid hsl(0, 0%, 80%);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%232e2e2e' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px 12px;
  appearance: none;
  transition: background-color 0.2s ease, border-color 0.2s ease, box-shadow 0.2s ease;
}

.d-select:focus {
  border-color: hsl(0, 0%, 50%);
  box-shadow: 0 0 0 0.25rem hsl(0, 0%, 40%, 0.5);
  outline: 0;
}

.d-select.disabled,
.d-select:disabled {
  background-color: hsl(0, 0%, 90%);
}

body.dark .d-select {
  background-color: hsl(0, 0%, 12.5%);
  border: 1px solid hsl(0, 0%, 20%);
  color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

body.dark .d-select:focus {
  border-color: hsl(0, 0%, 35%);
  box-shadow: 0 0 0 0.25rem hsl(0, 0%, 30%, 0.5);
  outline: 0;
}

body.dark .d-select.disabled,
body.dark .d-select:disabled {
  background-color: hsl(0, 0%, 20%);
}

.d-input {
  font-size: 1rem;
  padding: 6px 12px;
  border-radius: 6px;
  background-color: hsl(0, 0%, 100%);
  border: 1px solid hsl(0, 0%, 80%);
  color: #2e2e2e;
  transition: background-color 0.2s ease, border-color 0.2s ease, box-shadow 0.2s ease;
  display: block;
  width: 100%;
}

.d-input:focus {
  border-color: hsl(0, 0%, 50%);
  box-shadow: 0 0 0 0.25rem hsl(0, 0%, 40%, 0.5);
  outline: 0;
}

.d-input.disabled,
.d-input:disabled {
  background-color: hsl(0, 0%, 90%);
}

body.dark .d-input {
  background-color: hsl(0, 0%, 12.5%);
  border: 1px solid hsl(0, 0%, 20%);
  color: #fff;
}

body.dark .d-input:focus {
  border-color: hsl(0, 0%, 35%);
  box-shadow: 0 0 0 0.25rem hsl(0, 0%, 30%, 0.5);
  outline: 0;
}

body.dark .d-input.disabled,
body.dark .d-input:disabled {
  background-color: hsl(0, 0%, 20%);
}

//////////////////////////////////
.form-floating .form-control {
  font-size: 1rem;
  padding: 6px 12px;
  border-radius: 6px;
  background-color: hsl(0, 0%, 100%);
  border: 1px solid hsl(0, 0%, 80%);
  color: #2e2e2e;
  transition: background-color 0.2s ease, border-color 0.2s ease, box-shadow 0.2s ease;
  display: block;
  width: 100%;
}

.form-floating .form-control:focus {
  border-color: hsl(0, 0%, 50%);
  box-shadow: 0 0 0 0.25rem hsl(0, 0%, 40%, 0.5);
  outline: 0;
}

.form-floating .form-control.disabled,
.form-floating .form-control:disabled {
  background-color: hsl(0, 0%, 90%);
}

body.dark .form-floating .form-control {
  background-color: hsl(0, 0%, 12.5%);
  border: 1px solid hsl(0, 0%, 20%);
  color: #fff;
}

body.dark .form-floating .form-control:focus {
  border-color: hsl(0, 0%, 35%);
  box-shadow: 0 0 0 0.25rem hsl(0, 0%, 30%, 0.5);
  outline: 0;
}

body.dark .form-floating .form-control.disabled,
body.dark .form-floating .form-control:disabled {
  background-color: hsl(0, 0%, 20%);
}
////////////////////////////////////

.d-button {
  font-size: 1rem;
  padding: 6px 12px 6px 12px;
  border-radius: 6px;
  background-color: hsl(0, 0%, 18%);
  border: 1px solid hsl(0, 0%, 18%);
  color: #fff;
  transition: background-color 0.2s ease, border-color 0.2s ease, box-shadow 0.2s ease;
}

.d-button:hover {
  background-color: hsl(0, 0%, 28%);
  border-color: hsl(0, 0%, 28%);
}

.d-button:active {
  background-color: hsl(0, 0%, 38%);
  border-color: hsl(0, 0%, 38%);
}

.d-button:focus-visible {
  background-color: hsl(0, 0%, 28%);
  border-color: hsl(0, 0%, 28%);
  box-shadow: 0 0 0 0.25rem hsl(0, 0%, 28%, 0.5);
  outline: 0;
}

.d-button.disabled,
.d-button:disabled {
  pointer-events: none;
  background-color: hsl(0, 0%, 18%);
  border-color: hsl(0, 0%, 18%);
  opacity: 0.65;
}

body.dark .d-button {
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 100%);
  color: #2e2e2e;
}

body.dark .d-button:hover {
  background-color: hsl(0, 0%, 90%);
  border-color: hsl(0, 0%, 90%);
}

body.dark .d-button:active {
  background-color: hsl(0, 0%, 80%);
  border-color: hsl(0, 0%, 80%);
}

body.dark .d-button:focus-visible {
  background-color: hsl(0, 0%, 90%);
  border-color: hsl(0, 0%, 90%);
  box-shadow: 0 0 0 0.25rem hsl(0, 0%, 90%, 0.5);
}

body.dark .d-button.disabled,
body.dark .d-button:disabled {
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 100%);
}

.d-button.button-secondary {
  font-size: 1rem;
  padding: 6px 12px 6px 12px;
  border-radius: 6px;
  background-color: hsl(0, 0%, 100%);
  border: 1px solid hsl(0, 0%, 100%);
  color: #2e2e2e;
  transition: background-color 0.2s ease, border-color 0.2s ease, box-shadow 0.2s ease;
}

.d-button.button-secondary:hover {
  background-color: hsl(0, 0%, 90%);
  border-color: hsl(0, 0%, 90%);
}

.d-button.button-secondary:active {
  background-color: hsl(0, 0%, 80%);
  border-color: hsl(0, 0%, 80%);
}

.d-button.button-secondary:focus-visible {
  background-color: hsl(0, 0%, 90%);
  border-color: hsl(0, 0%, 90%);
  box-shadow: 0 0 0 0.25rem hsl(0, 0%, 90%, 0.5);
  outline: 0;
}

.d-button.button-secondary.disabled,
.d-button.button-secondary:disabled {
  pointer-events: none;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 100%);
  opacity: 0.65;
}

body.dark .d-button.button-secondary {
  background-color: hsl(0, 0%, 18%);
  border-color: hsl(0, 0%, 18%);
  color: #fff;
}

body.dark .d-button.button-secondary:hover {
  background-color: hsl(0, 0%, 28%);
  border-color: hsl(0, 0%, 28%);
}

body.dark .d-button.button-secondary:active {
  background-color: hsl(0, 0%, 38%);
  border-color: hsl(0, 0%, 38%);
}

body.dark .d-button.button-secondary:focus-visible {
  background-color: hsl(0, 0%, 28%);
  border-color: hsl(0, 0%, 28%);
  box-shadow: 0 0 0 0.25rem hsl(0, 0%, 28%, 0.5);
}

body.dark .d-button.button-secondary.disabled,
body.dark .d-button.button-secondary:disabled {
  background-color: hsl(0, 0%, 18%);
  border-color: hsl(0, 0%, 18%);
}
</style>
